// import { useContext} from 'react'
import { useTranslation } from 'react-i18next';

import { useCart } from '../context/cart.ts';
import { parseJSON, hashCode } from '../util/index';

const useCartAction = () => {
  const [state, actions] = useCart();
  const { t } = useTranslation('shop');

  // eslint-disable-next-line
  const defValue = (keyStr, item, jm) => {
    // console.log('CARTDEF', item);
    const quantityMaxActual = Math.max(0, typeof item.zapas.actual === 'boolean' ? +item?.zapas_wolny_q : +item.zapas.actual);

    return {
      hash: hashCode(item.symbol_art),
      id: 0,
      input: 1,
      confirm: 0,
      max: parseFloat(quantityMaxActual),
      title: item?.nazwa_art || '---',
      symbol: item.symbol_art,
      label_name: item?.label_name || 'Symbol',
      price: parseFloat(item?.cena_jm || item.cena),
      price_base: parseFloat(item?.cena_nom || item?.cena_n || 0),
      price_feat: parseFloat(item?.cena_cech || 0),
      price_purchase: parseFloat(item?.cena_zak_n || 0),
      price_fixed: parseFloat(item?.cena_stala || 0), // price edit by prop feature
      price_discount: 0, // price edit by client merchant
      currency: item?.currency || 'zł',
      tax_rate: parseFloat(item?.tax_rate) || parseFloat(item?.tax_info?.rate) || 1,
      jm: jm?.symbol_jmp || item?.symbol_jmp || '',
      convert: parseFloat(jm?.przelicz || item?.przelicz || 1),
      precision: parseFloat(jm?.precision || item?.precision || 0),
      // property: state.property[keyStr] || item?.property || {}, // property obj
      property: item?.property || {}, // property obj
      description: '',
      // calc_stock: item?.calc_stock || false // not used
    };
  };

  const defJmPom = (item) => (
    {
      nazwa_jm: item?.symbol_jmp || item?.symbol_jm, // symbol_jmp cart, symbol_jm product
      symbol_jmp: item?.symbol_jmp || item?.symbol_jm,
      przelicz: item.przelicz,
      podstawowa: 1,
    }
  );

  const defZapas = (item) => {
    if (item['zapas'] !== undefined && item['zapas'] !== null) return;

    // eslint dot-notation: "error"
    item['zapas'] = {
      actual: 0,
      max: 0,
      min: 0,
      stock_show: 1,
    };
  };

  const getDefaultJM = (item, symbolJM = undefined) => {
    let jmDefault = { symbol_jmp: '' }; // default jm_pom: szt.

    if (item['jm_pom'] === undefined || item['jm_pom'] === null) {
      jmDefault = defJmPom(item);
      return jmDefault;
    }

    const jmFiltered = item.jm_pom
      .sort((a, _) => a.podstawowa == 1 && a.visible == 1 ? -1 : 1)
      .filter(el => el.visible == 1);

    if (jmFiltered.length == 0) {
      jmDefault = defJmPom(item);
      return jmDefault;
    }

    jmDefault = jmFiltered.find((el) => (
      el.symbol_jmp === (symbolJM || state.measure[item.symbol_art] || item.symbol_jm)) || (el.symbol_jmp === '')
    );

    if (jmDefault == undefined)
      jmDefault = [...jmFiltered][0];

    if (jmDefault && jmDefault?.nazwa_jm == '')
      jmDefault.nazwa_jm = jmDefault.symbol_jmp;

    return jmDefault;
  };

  const getMeasureJM = (item) => { // get exists measure jm
    if (item?.jm_pom == undefined) return "";

    const jmFound = item.jm_pom
      .sort((a, _) => a.podstawowa == 1 && a.visible == 1 ? -1 : 1)
      .filter(el => el.visible == 1)
      .find(el => el.symbol_jmp == state.measure[item.symbol_art]);

    if (!jmFound) return "";

    return state.measure[item.symbol_art] || ""; // current selected jm
  };

  // eslint-disable-next-line
  const getKey = (item, symbolJm = undefined, selectCart = undefined) => {
    // console.log("%cGETKEY", "color:red", symbolJm, state.measure[item.symbol_art], getDefaultJM(item).symbol_jmp);
    const key = ({
      cart: selectCart || state.cart.selectNormal, // current selected cart id
      symbol: item.symbol_art,
      jm: (symbolJm === undefined) ? getMeasureJM(item) || getDefaultJM(item).symbol_jmp || "" : symbolJm || "", // current selected jm
    });

    return key;
  };

  /** CART ACTION */
  const cartAction = {
    getProduct: ({
      item,
      symbolJM = undefined /* string */,
      selectCart = undefined,
      pos = 0, /* number */
    }) => {
      defZapas(item);

      const productKey = getKey(item, symbolJM, selectCart);
      const keyStr = JSON.stringify(productKey);
      const productJm = getDefaultJM(item, symbolJM);
      let productPoz = +pos || (+state.position[keyStr] || 0);

      if (productPoz == 0 && state.position[keyStr] == undefined)
        productPoz = state.product.getLastPosition(productKey);

      // debugger;
      const product = defValue(keyStr, item, productJm);
      // console.log("GET PRODUCT", productKey, product, productJm, productPoz);

      if (!state.product.has(productKey, productPoz)) {
        if (productPoz == 0) {
          state.product.set(productKey, productPoz, { ...product });
        }
        return product;
      }

      const productState = state.product.get(productKey, productPoz);
      // console.log("GET PRODUCT STATE", productKey, productPoz, productState);
      if (productState == undefined) {
        return product;
      }

      return ({
        ...product,
        hash: productState.hash,
        id: productState.id,
        input: productState.input,
        confirm: productState.confirm,
        property: productState.property,
        description: productState.description,
        price_discount: +productState.price_discount,
        price_feat: +productState.price_feat,
        price_fixed: +productState.price_fixed,
        price_purchase: +productState.price_purchase,
      });
    },
    setProduct: async ({
      item,
      symbolJM = undefined /* string */,
      quantity = { } /* object */,
      force = false,
      price = 0, /* force price */
      priceDiscount = -1,
      pos = 0, /* number poz_id */
      selectCart = undefined,
    }) => {
      const productKey = getKey(item, symbolJM, selectCart);
      const product = { ...cartAction.getProduct({ item, symbolJM, pos }) };
      const productPrice = price > 0 ? price : product.price;
      const productPriceDiscount = priceDiscount > -1 ? priceDiscount : product.price_discount;
      const productPriceFixed = product.price_fixed || 0;
      const productPoz = (product.id || pos); // undefined is important

      const productValue = {
        ...product,
        price: +productPrice,
        price_discount: +productPriceDiscount,
        price_fixed: +productPriceFixed,
        input: quantity.input,
        confirm: quantity.confirm,
      };

      state.error = { hash: 0, msg: '' };
      state.product.set(
        productKey,
        productPoz,
        productValue,
      );

      console.log("SET PRODUCT", productKey, productValue, productPoz);

      const result = ({ data: -1, err: false });
      if (product.confirm === quantity.confirm && force !== true) {
        return result;
      }

      // console.log('UPDATE actions', key.cart);
      if (quantity.confirm === 0) {
        ({ data: result.data, err: result.err } = await actions.delProduct(productKey, productPoz));
      } else {
        ({ data: result.data, err: result.err } = await actions.setProduct({ productKey, productPoz, selectCart: state.cart.selectNormal }));
      }

      if (result.err !== false && product !== undefined) {
        /* eslint no-param-reassign: "error" */
        quantity.input = parseFloat(product.input);
        quantity.confirm = parseFloat(product.confirm);
        actions.revertProduct(productKey, productPoz, product);
      }

      return result;
    },
    getProductConfirm: (item) => {
      const cartSelect = state.cart.selectNormal;
      const quantityConfirm = state.product.confirmProduct(cartSelect, item.symbol_art);
      // console.log('QC', quantityConfirm);
      return quantityConfirm;
    },
    getQuantityMax: ({ item, maxActual = 0, jmCurrent = undefined /* string symbol_jmp */, priorityMeasure = false, stock_unlimit = 0 }) => {
      if (stock_unlimit == 1) return ({ current: -1, actual: 0 });

      const quantityMaxActual = Math.max(0, (typeof maxActual === 'boolean') ? +item?.zapas_wolny_q : +maxActual);

      const quantityMax = quantityMaxActual;
      let quantityConvert = 1;
      let quantityMaxJm = 0;
      let quantityMaxJmSel = 0;
      let jmSelected = jmCurrent;

      if (getMeasureJM(item) /* not_found == "" */ != undefined && priorityMeasure == true) {
        jmSelected = state.measure[item.symbol_art] || jmCurrent;
      }

      state.product.findByProductSymbol(item.symbol_art, '', state.cart.selectNormal)
        .find(product => {
          if (jmSelected == product.jm) {
            quantityConvert = parseFloat(product.convert);
          } else {
            quantityMaxJmSel += parseFloat(product.confirm) * parseFloat(product.convert);
          }

          quantityMaxJm += parseFloat(product.confirm) * parseFloat(product.convert);
        });

      return ({
        current: parseFloat(Math.max(0, Math.floor((quantityMax - quantityMaxJm) / quantityConvert))), // max
        actual: parseFloat(Math.max(0, Math.floor((quantityMax - quantityMaxJmSel) / quantityConvert))), // maxActual
      });
    },
    getProperty: (item, pos = 0) => {
      const productKey = getKey(item, undefined, undefined); // item, jm, cart
      const productPos = +pos;

      return actions.getProperty(productKey, productPos);
    },
    setProperty: (item, property, propPrice = 0, itemPrice = 0, pos = 0, reload = false) => {
      const productKey = getKey(item, undefined, undefined); // item, jm, cart
      let productPos = +pos;

      if (pos === 0) {
        productPos = state.product.findPosByProperty(productKey, property);
        // console.log("%cFINDPOS", "color:red", productKey, productPos, property);
      }
      // console.log("SETPROPERTY", productKey, item, property, productPos, reload)

      actions.setPosition(productKey, productPos);
      cartAction.getProduct({ item, pos: productPos }); // set store
      return actions.setProperty(productKey, productPos, property, propPrice, itemPrice, reload);
    },
    getPosition: (item) => {
      const productKey = getKey(item, undefined, undefined); // item, jm - default, cart - default
      return actions.getPosition(productKey);
    },
    setDescription: (item, pos = 0, description = '') => {
      const productKey = getKey(item, undefined, undefined); // item, jm - default, cart - default
      const productPos = +pos;

      return actions.setDescription(productKey, productPos, description);
    },
    getMeasure: (item) => getMeasureJM(item) || item?.symbol_jm || item?.symbol_jmp,
    setMeasure: (item, value) => {
      actions.setMeasure({ [item.symbol_art]: value });
    },
    selectCart: (id, typ = 'Z') => {
      actions.selectCart(id, typ);
    },
    selectCartFirst: (typ) => {
      const cart = state.cart.findAsTyp(typ);
      if (cart === undefined) return false;

      actions.selectCart(cart.id, typ);
      return cart.id;
    },
    getCartName: (id, typ = 'Z') => { // object
      if (!state.cart || state.cart.length === 0) return '-';

      const cart = state.cart.get(id);
      // console.log('getCartName', id, cart);
      return cart?.name || (typ == 'Z' ? t('Koszyk') + '...': t('Wzorzec') + '...');
    },
    newPatternCopy: async (name = t('Wzorzec'), cartId = 0) => {
      const cart = await actions.setCart(name, 0, 'W');
      if (cart === false || (!cart?.id > 0)) return false;

      actions.selectCart(cart?.id, 'W');

      state.product.entries().forEach(async ([key, product]) => {
        if (key == 0) return;

        const productKey = parseJSON(key);
        if (productKey?.cart !== cartId) return;
        if (product.confirm <= 0 || product.confirm == undefined) return;

        console.log('%cPATTERN product', 'color:green', product, productKey, cartId);
        await actions.setProduct({
          productKey,
          productPoz: product.id,
          selectCart: cart?.id,
          newProduct: 0, // new product
        });
      });

      return cart?.id;
    },
    newCart: async (name = t('Nowy'), typ = 'Z') => {
      const cart = await actions.setCart(name, 0, typ);
      if (cart === false || (!cart?.id > 0)) return false;
      return cart?.id;
    },
    getCart: async (cartId, typ = 'Z') => {
      const data = await actions.getCart(cartId, typ);
      return data;
    },
    getCartElement: async (cartId, typ = 'Z') => {
      const data = await actions.getCartElement(0, cartId, typ);
      return data;
    },
    setCart: async (name = t('Inny'), select = 0, typ = 'Z') => {
      const cart = await actions.setCart(name, select, typ);
      return cart?.id;
    },
    setCartExtension: async (id, dataExt, typ = 'Z') => {
      const flag = await actions.setCartExtra(id, typ, dataExt, {});
      return flag;
    },
    getCartExtension: (id) => {
      const cart = state.cart.findById(id);
      if (cart === false) return {};
      return cart.ext;
    },
    setCartThirdSide: async (id, dataTs, typ = 'Z') => {
      const flag = await actions.setCartExtra(id, typ, {}, dataTs);
      return flag;
    },
    getCartThirdSide: (id) => {
      const cart = state.cart.findById(id);
      if (cart === false) return {};
      return cart.ts;
    },
    delCart: async (id, typ = 'Z') => (
      actions.delCart(id, typ)
    ),
    copyCart: async (cartIdFrom, cartIdTo = 0, multiplier = 1) => {
      const cartCount = state.cart.filterAsTyp('Z').length;
      const name = cartIdTo > 0 ? cartAction.getCartName(cartIdTo) : t('Koszyk') + ` ${Number(cartCount).toLetters()}`;

      const cartForm = await actions.getCartInfo(cartIdFrom, 'W');
      const cart = await actions.setCart(name, cartIdTo, 'Z', cartForm?.ext || {}, cartForm?.ts || {});
      if (cart === false || (!cart?.id > 0)) return false;

      actions.selectCart(cart?.id, 'Z');

      state.product.entries().forEach(async ([key, product]) => {
        if (key == 0) return;

        const productKey = parseJSON(key);
        if (productKey?.cart !== cartIdFrom) return;

        if (product.confirm <= 0 || product.confirm == undefined) return;

        const productFound = state.product.findByProductSymbol(productKey.symbol, productKey.jm, cart?.id);
        const newProductId = +(productFound.lastElement())?.id;
        const addQuantity = +(productFound.lastElement())?.confirm;

        console.log('CART product', productKey, product, cartIdFrom, cartIdTo, productFound, addQuantity);
        const result = await actions.setProduct({
          productKey,
          productPoz: product.id,
          selectCart: cart?.id,
          multiplier,
          calcStock: true,
          calcPrice: true,
          newProduct: newProductId, // if exists = replace or new
          addQuantity, // additional quantity
          calcSum: false,
        });

        // console.log('CART result', productKey, result);
        if (result.err === false) {
          await actions.getCartElement(result.data /*pozId*/, cart.id);
        }
      });

      actions.calcSum();
      return cart?.id;
    },
    isCartEmpty: (id) => (
      state.product.countProduct(id) === 0
    ),
    calcCart: () => {
      actions.calcSum();
    },
    notifySelect: () => {
      actions.notifySelect();
    },
    getProductState: () => (
      actions.getProductState()
    ),
    setFavoriteCnt: (cnt, inc = false) => {
      actions.setFavoriteCnt(cnt, inc);
    },
    isCartDisable: (item) => {
      if (item.isabstractmodel)
        return { status: true, reason: 'abstrakcyjny model', date: '', block: false };

      if (item.cena == 0)
        return { status: true, reason: 'cena bazowa = 0' , date: '', block: false };

      if (!!item?.b2b_block && item.b2b_bl_d.compareTimeStampNow() > 0)
        return { status: true, reason: 'blokada do', date: item.b2b_bl_d.slice(0, 10), block: true };

      return { status: false, reason: '', date: '', block: false };
    },
    setLoading: (loading) => {
      actions.setLoading(!!loading);
    },
  };

  return [state, cartAction];
};

export default useCartAction;
